import { cookie } from './analytics.config.js';

export default class Analytics {
  constructor(componentContainer) {
    this.el = componentContainer;
    window.dataLayer = window.dataLayer || [];
    if (this.el) {
      this.setCookieValue();
      this.init();
    }
  }

  init() {} // eslint-disable-line class-methods-use-this

  // The cookie mutates when it is created the first time until it reaches its full data.
  // To prevent JS blocking errors, we make sure that the cookie is full. To do so, we check it by parts.
  // If the cookie is not full, then we don't save its value
  setCookieValue() {
    const isFullCookie = document.cookie.includes(cookie.analytics.name) && document.cookie.includes('groups=');
    if (isFullCookie) {
      const analyticsCookiePartial = document.cookie.split(`${cookie.analytics.name}=`)[1];
      if (analyticsCookiePartial) {
        const analyticsCookieValue = analyticsCookiePartial.split(';')[0];
        if (analyticsCookieValue) {
          window.analyticsCookieValue = analyticsCookieValue; /** We only need the cookie to be found once in a page */
        }
      }
    }
  }

  isCookieAccepted() {
    const cookieValueGroups = window.analyticsCookieValue.split('groups=')[1].split('&')[0];
    return cookieValueGroups.slice(-1) === '1';
  }

  isCookieRejected() {
    const landingPath = window.analyticsCookieValue.split('landingPath=')[1].split('&')[0];
    return !this.isCookieAccepted() && landingPath === 'NotLandingPage';
  }

  pushAnalytics(eventObject) {
    // https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag
    if (window.gtag) {
      window.gtag('event', eventObject.event, eventObject);
    } else {
      window.dataLayer.push(eventObject);
    }
  }

  push2AdobeDataLayer(eventObject) {
    // push only if the adobeDataLayer is defined, as this is set per authoring
    if (window.adobeDataLayer) {
      window.adobeDataLayer.push(eventObject);
    }
    
  }
}
