export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function isObjectNotWindow(item) {
  return isObject(item) && item !== window;
}

/**
 * Deep merges objects only. Arrays are not extended.
 * The `window` object will not be extended.
 * @param {Object} origin - The object that will be extended
 * @param  {...any} toMerge - the objects that will be merged with `origin`
 * @returns
 */
export function deepMerge(origin, ...toMerge) {
  if (!toMerge.length) return origin;
  const merge = toMerge.shift();

  if (isObjectNotWindow(origin) && isObjectNotWindow(merge)) {
    Object.keys(merge).forEach(key => {
      if (isObjectNotWindow(merge[key])) {
        if (!origin[key]) Object.assign(origin, { [key]: {} });
        deepMerge(origin[key], merge[key]);
      } else {
        Object.assign(origin, { [key]: merge[key] });
      }
    });
  }

  return deepMerge(origin, ...toMerge);
}
