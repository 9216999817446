/**
 * Get i18n in AEM if available
 */
const Granite = window.Granite;
export const i18n = item => {
  if (Granite && Granite.I18n && Granite.I18n.get) {
    return Granite.I18n.get(item);
  }
  return item;
};
