import { getCookie } from 'commons/utils';

// ========== SHOPIFY integration =========================

// Used by dynamiclist and dynamicproduct
export const fetchProductsFromShopify = (shopifyUrl, shopifyAccessToken, graphql) => {
  const myHeaders = new Headers();
  myHeaders.append('X-Shopify-Storefront-Access-Token', `${shopifyAccessToken}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow',
  };

  const fetchedProducts = fetch(`${shopifyUrl}`, requestOptions)
    .then(response => response.json())
    .catch(error => {
      throw new Error(`fetchedProducts() => an error occurred: ${error.message}`)
    });

  return fetchedProducts;
};

// Used by Vuse Canada only
export const fetchFromShopifyRestAPI = (storeDomain) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const fetchedProducts = fetch(`${storeDomain}`, requestOptions)
    .then(response => response.json())
    .catch(error => {
      throw new Error(`fetchedProducts() => an error occurred: ${error.message}`)
    });

  return fetchedProducts;
};

// Used by E-commerce Mini Basket component and Account Icon component
export const cleanShopifyCartId = (cartId) => {
  let cleanCartId = cartId;
  if (cleanCartId.includes('gid://shopify/Cart')) {
    cleanCartId = cleanCartId.substring(cleanCartId.indexOf("Cart/") + 5);
  }
  cleanCartId = decodeURIComponent(cleanCartId);

  return cleanCartId;
}

export const addProductToMiniBasket = (config, miniBasketConfig) => {
  const {
    addToBasketButtonEl,
    merchandiseId,
    quantity,
    eventType,
    shouldOpenMiniBasket,
    classes,
  } = miniBasketConfig;

  let cartId = '';
  const cartIdCookie = getCookie(config.cartCookieName);

  if (cartIdCookie) {
    cartId = cleanShopifyCartId(cartIdCookie);
  }

   const store = {
    storeDomain: config.shopifyUrl,
    publicAccessToken: config.shopifyAccessToken,
    query: cartId ? config.addProductToCart : config.addProductAndCreateCart,
  };

  let graphql = {};

  // prepare graphql
  if (cartId) {
     graphql = JSON.stringify({
      query: store.query,
      variables: {
        "cartId": `gid://shopify/Cart/${cartId}`, // refactor this
        "lines": [{"merchandiseId": merchandiseId, "quantity": parseInt(quantity, 10)}],
      },
    });
  } else {
    graphql = JSON.stringify({
      query: store.query,
      variables: {
        "lines": [{"merchandiseId": merchandiseId, "quantity": parseInt(quantity, 10)}],
      },
    });
  }

  // prepare for sending and send request
  addToBasketButtonEl.setAttribute('disabled', true);
  const buttonLabel = miniBasketConfig.addToBasketButtonEl.textContent;
  addToBasketButtonEl.innerHTML = '<span class="batcom--loading"></span>';

  const fetchedProducts = fetchProductsFromShopify(store.storeDomain, store.publicAccessToken, graphql);
  fetchedProducts.then((response) => {
    addToBasketButtonEl.textContent = buttonLabel;
    addToBasketButtonEl.classList.add(classes.isSuccess);

    window.setTimeout(() => {
      addToBasketButtonEl.classList.remove(classes.isSuccess);
      addToBasketButtonEl.removeAttribute('disabled');
      window.PubSub.publish(eventType, {
        openBasket: shouldOpenMiniBasket,
        response,
      });
    }, 1000);
  });
}

// Share the cartID
export const hrefCartId = (cartId) => cartId.replace('?', '&')

// Used by E-commerce Mini Basket component and Vuse Canada E-commerce Mini Basket
export const formatCurrency = (currency, amount, locale = document.documentElement.lang) => {
  const amountNumber = Number(amount);

  // Currency need to be configured in page properties in advanced tab (Language) for every page
  // Also it need to be configred in Vuse Canada E-commerce Mini Basket dialog, for E-commerce Mini Basket component this is not needed.
  if (!currency || typeof amountNumber !== 'number') {
    // eslint-disable-next-line no-console
    console.error(`formatCurrency() => Currency: '${currency}' or amount: '${amount}' is not provided!`);
    return '';
  }

  const configuredValue = new Intl.NumberFormat(locale, {style: 'currency', currency})
  return configuredValue.format(amountNumber)
};

// ========== end SHOPIFY integration =========================

export const getProductName = (metafields, option ) => {
  for (let i = 0; i < metafields.length; i++) {
    if (metafields[i] && metafields[i].key === option) {
      return metafields[i].value;
    }
  }
  return '';
};

export const getMetafieldValueByKey = (metafields, key) => {
  if (!metafields) {
    return '';
  }

  for (let i = 0; i < metafields.length; i++) {
    if (metafields[i] && metafields[i].key === key) {
      return metafields[i].value;
    }
  }
  return '';
};

export const getPriceSnippet = (rawPrice, rawCompareAtPrice, currencyCode, priceSnippet, savingsLabel, regularPriceLabel) => {
  const price = parseFloat(rawPrice);
  const compareAtPrice = parseFloat(rawCompareAtPrice);
  priceSnippet.textContent = '';
  if (compareAtPrice && price < compareAtPrice) {
    const discount = Math.floor(((compareAtPrice - price) / compareAtPrice) * 100);
    const currentPrice = formatCurrency(currencyCode, price);
    const normalPrice = formatCurrency(currencyCode, compareAtPrice);
    const discountHTML = document.createElement('div');
    const normalPriceHTML = document.createElement('div');
    discountHTML.classList.add('product-card__price-discount');
    normalPriceHTML.classList.add('product-card__price-regular');
    discountHTML.innerHTML = `
    <span class="product-card__price-discount--price">${currentPrice}</span>
    <span class="product-card__price-discount-label">${savingsLabel}</span>
    <span class="product-card__price-discount--precentage">${discount}%</span>`;
    normalPriceHTML.innerHTML = `<span class="product-card__price-regular--price">${regularPriceLabel} ${normalPrice}</span>`;
    priceSnippet.appendChild(discountHTML);
    priceSnippet.appendChild(normalPriceHTML);
  } else {
    priceSnippet.innerHTML = formatCurrency(currencyCode, price);
  }
};

export const renderCapsules = (template, metafields, capsulesIconsUrl, conf, pdp) => {
    const capsuleWrapper = template.querySelector(`[data-key="capsules"]`);
    const capsules = getMetafieldValueByKey(metafields, 'capsules');

    if (!capsules || !capsuleWrapper) {
      return;
    }

    JSON.parse(capsules).forEach((capsule) => {
      let capsuleElement = '';
      capsuleElement = document.createElement('span');
      capsuleElement.className = "product-card__capsule";
      capsuleElement.dataset.key = `capsule-${capsule}`;
      capsuleElement.innerHTML = `<img src="${capsulesIconsUrl}/${capsule}.svg" alt="${capsule}" class="product-card__capsule-icon" data-key="capsule-icon" />`;
      capsuleWrapper.appendChild(capsuleElement);
    });
    capsuleWrapper.classList.remove(conf.classes.hidden);

    if (pdp === 'pdp') {
      const productGallerySwiper = document.querySelector(conf.selectors.swipeMain);
      productGallerySwiper?.classList.add(conf.classes.swiperWithCapsules);
      productGallerySwiper && productGallerySwiper.appendChild(capsuleWrapper);
    }
  }

export const renderDots = (template, metafields, key, config, pdp) => {
  const dotsNumberElement = pdp ? template.querySelector(`[data-key="${key}-dots-number"]`) : template.querySelector('[data-key="dots-number"]');
  const numberValue = Number(getMetafieldValueByKey(metafields, key));
  const levelIndicatorContainers = template.querySelectorAll(config.selectors.levelIndicatorContainer);

  if (!numberValue || !dotsNumberElement) {
    return;
  }

  levelIndicatorContainers?.forEach((container) => container.classList.remove(config.classes.hidden));

// strengthIntensityLevelLabel
  if (config[`${key}Label`] && !pdp) {
    template.querySelector(`[data-key="${key}Label"]`).innerText = config[`${key}Label`];
  }

  dotsNumberElement.innerText = numberValue;

  const dots = pdp ? template.querySelectorAll(`[data-key="${key}-dot"]`) : template.querySelectorAll(config.selectors.dot);
  dots.forEach((el, i) => {
    if (i <= (numberValue - 1)) {
      el.classList.add(config.classes.filled);
    }
  });

  pdp ? template.querySelector(`[data-key="${key}"]`).classList.remove(config.classes.hidden) : template.querySelector(config.selectors.dotsWrapper).classList.remove(config.classes.hidden);
}

export const renderBadges = (template, tags) => {

  if (!tags.length || !template) {
    return;
  }

  const badgeText = (badge, text) => {
    if (badge) {
      badge.textContent = text;
      badge.classList.remove('batcom-hide');
    }
  };

  const updateBadge = (prefix, selector) => {
    const index = tags.findIndex(tag => tag.startsWith(prefix));
    if (index !== -1) {
      const badgeTextContent = tags[index].replace(prefix, '');
      const badgeElement = template.querySelector(`[data-key="${selector}"]`);
      if (badgeElement) {
        badgeText(badgeElement, badgeTextContent);
      }
    }
  };

  // possible badges with anchor in html as data-key
  const badges = {
    promo: '&PROMO',
    badge: '&BADGE',
  };

  Object.entries(badges).forEach(([selector, prefix]) => {
    updateBadge(prefix, selector);
  });
};

export const updateProductLink = (product, productHtml, config) => {
  if (!productHtml || !product.node.handle || !config.productPagesUrl) {
    return
  }

  const productLinks = productHtml.querySelectorAll(config.selectors.productDetailPageLink);
  productLinks.forEach(productLink => {
    if (productLink) {
      productLink.href = `${config.productPagesUrl}${product.node.handle}`;
    }
  });
}

const objKeysToLowerCase = (obj) => {
  const keys = Object.keys(obj);
  const newObj = {};
  for (let i = 0; i < keys.length; i++) {
    newObj[keys[i].toLowerCase()] = obj[keys[i]];
  }
  return newObj;
}

export const mapColorToHex = (color, colorVariants) => {
  const currentColor = color.toLowerCase();
  const hexColors = objKeysToLowerCase(colorVariants);
  return hexColors[currentColor] || hexColors.default;
}

export const strengthOverlapping = (strengthArray) => strengthArray.filter((value, index, array) => array.indexOf(value) !== index);

export const strengthMapping = (strengthLevels, strength) => strengthLevels.filter((mapping) => mapping.label === strength);

export const renderstrengthLevels = (config, filters) => {
  const strengthValue = config.strength || config.nicotineLevel;
  const strengthMap = strengthMapping(filters ? config.strengthMappings : config.nicotineStrengthMappings, strengthValue);
  const strengthLevels = document.createElement('div');
  strengthLevels.classList.add(filters ? 'batcom-dynamiclist-filters__strength-levels' : 'product-card__strength-levels');

  if (!strengthMap.length) {
    // eslint-disable-next-line no-console
    console.error(`renderstrengthLevels() => No strength mapping found for the ${strengthValue} strength!`);
  } else {
    const maximumIndicatorLevel = strengthMap[0].maximumIndicatorLevel;
    const indicatorLevel = strengthMap[0].indicatorLevel;
    // Create span for each indicator
    for (let i = 0; i < maximumIndicatorLevel; i++) {
      const newIndicator = document.createElement('span');
      newIndicator.classList.add(filters ? 'batcom-dynamiclist-filters__strength-level' : 'product-card__strength-level');
      if (i < indicatorLevel) {
        newIndicator.classList.add(filters ? 'batcom-dynamiclist-filters__strength-level--filled' : 'product-card__strength-level--filled');
      }
      strengthLevels.appendChild(newIndicator);
    }
  }

  return strengthLevels;
}

// Velo => update active strength buttons based on active size option
const updateActiveStrengthButtons = (strengthButtons, activeSizeOption, onlyStrengthButton, config) => {
  strengthButtons && strengthButtons.forEach(element => {
    if (element.getAttribute('data-key-size') === activeSizeOption || onlyStrengthButton) {
      element.classList.remove(config.classes.hidden);
    }
  });
}

// Velo render Size Buttons
export const renderSizeButtons = (productHTML, productOptions, config) => {
  if (!productOptions) {
    return;
  }

  const sizeButtonWrapper = productHTML.querySelector('[data-key="size-buttons"]');
  const sizeOptions = productOptions?.find(option => option.name === 'Size');
  const strengthButtons = productHTML.querySelectorAll('.product-card__strength-button');

  sizeOptions && sizeOptions.values.forEach((size) => {
    const sizeBtn = document.createElement('button');
    sizeBtn.classList.add('product-card__size-button');
    sizeBtn.textContent = size.toUpperCase();
    sizeBtn.setAttribute('data-key-size', size);
    sizeBtn.setAttribute('data-key', 'size-button');
    sizeButtonWrapper && sizeButtonWrapper.appendChild(sizeBtn);
  });

  sizeOptions && sizeButtonWrapper.classList.remove(config.classes.hidden);
  const sizeButtons = sizeButtonWrapper.querySelectorAll('[data-key="size-button"]');
  const activeStrengthButton = productHTML.querySelector('.product-card__strength-button.active');
  let activeSizeOption = null;
  let onlyStrengthButton = null;

  if (activeStrengthButton) {
    activeSizeOption = activeStrengthButton.getAttribute('data-key-size');
    sizeButtons.forEach(sizeButton => {
      if (sizeButton.getAttribute('data-key-size') === activeSizeOption) {
        sizeButton.classList.add(config.classes.active);
      }
    });
  }

  if (sizeButtons.length === 1) {
    sizeButtons[0].classList.add(config.classes.active);
    onlyStrengthButton = productHTML.querySelector('.product-card__strength-button');
  }

  updateActiveStrengthButtons(strengthButtons, activeSizeOption, onlyStrengthButton, config);
}

export const renderVariantButton = (btnConfigs) => {
  const variantBtn = document.createElement('button');
  if (btnConfigs.background) {
    variantBtn.classList.add('product-card__device-color-btn');
    variantBtn.dataset.key = 'color-button';
    variantBtn.setAttribute('data-key-colorName', btnConfigs.colorName);
    variantBtn.innerHTML = `<span class="product-card__device-color-btn-inside" style="background-color: ${btnConfigs.background}"></span>`;
  } else if (btnConfigs.nicotineLevel && (!btnConfigs.nicotineStrengthMappings || btnConfigs.nicotineStrengthMappings.length === 0)) {
    variantBtn.classList.add('product-card__nicotine-button');
    variantBtn.innerHTML = `<img src="${btnConfigs.iconsUrl}${btnConfigs.nicotineLevel}.svg" alt="${btnConfigs.alt}" class="product-card__nicotine-icon" data-key="nicotine-icon" />`;
  } else if (btnConfigs.nicotineStrengthMappings && btnConfigs.nicotineStrengthMappings.length > 0) {
    variantBtn.classList.add('product-card__strength-button');
    variantBtn.setAttribute('data-key-size', btnConfigs.size || btnConfigs.option);
    variantBtn.setAttribute('data-key-strength', btnConfigs.strength || btnConfigs.nicotineLevel);
    const strengthLevelLabel = document.createElement('span');
    strengthLevelLabel.classList.add('product-card__strength-level-label');
    strengthLevelLabel.textContent = btnConfigs.strength || btnConfigs.nicotineLevel;
    variantBtn.appendChild(strengthLevelLabel);
    const strengthLevels = renderstrengthLevels(btnConfigs);
    variantBtn.classList.add('batcom-hide');
    variantBtn.appendChild(strengthLevels);
  }

  if (btnConfigs.available === 0) {
    variantBtn.classList.add('disabled');
  }

  if (btnConfigs.active) {
    variantBtn.classList.add('active');
  }

  variantBtn.type = 'button';
  variantBtn.setAttribute('data-key-quantity-available', btnConfigs.available);
  variantBtn.setAttribute('data-key-product-image', btnConfigs.img);
  variantBtn.setAttribute('data-key-product-price', btnConfigs.price);
  variantBtn.setAttribute('data-key-product-compareAtPrice', btnConfigs.compareAtPrice);
  variantBtn.setAttribute('data-key-product-currencyCode', btnConfigs.currencyCode);
  variantBtn.setAttribute('data-key-merchandise-id', btnConfigs.merchandiseId);
  variantBtn.setAttribute('data-key-option', btnConfigs.selectedOption);
  variantBtn.setAttribute('data-key', 'variant-button');
  variantBtn.setAttribute('data-key-sku', btnConfigs.sku);
  return variantBtn;
}
