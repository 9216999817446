export const page = {
  events: {
    allPages: 'all_pages',
    viewItem: 'view_item',
    defaultPageView: 'defaultPageView',
  },
  selectors: {
    bffPdp: '.batcom-bff-dynamicproduct',
  },
};

export const search = {
  events: {
    search: 'search',
    unsuccesfulsearch: 'unsuccesfulsearch',
  },
  selectors: {
    searchInput: '.corp-quicksearch__input',
    resultsTotal: '.corp-search-results__pages--total',
  },
};

export const storeLocator = {
  events: {
    storeLocatorSearch: 'StoreLocatorSearch',
    storeLocatorUseLocation: 'StoreLocatorUseLocation',
  },
};

export const list = {
  events: {
    viewItemList: 'view_item_list',
    selectItem: 'select_item',
  },
  selectors: {
    slideName: '.product-card__name',
    slideShortName: '.product-card__shortName',
    slidePrice: '.product-card__price',
    slideId: '.product-card__id',
    listItem: '.cmp-list__item-js',
  },
};

export const bff = {
  events: {
    viewItemList: 'view_item_list',
    viewItem: 'view_item',
    selectItem: 'select_item',
    addToCart: 'add_to_cart',
  },
};

export const newsletter = {
  events: {
    newsletter: 'newsletter',
  },
};

export const cookie = {
  analytics: {
    name: 'OptanonConsent',
  },
  selctors: {
    onetrustConsentSdk: '#onetrust-consent-sdk',
  },
};

export const video = {
  events: {
    video: 'video',
    audio: 'audio',
  },
  selectors: {
    media: '.batcom-video__video, .batcom-video__audio',
  },
  classes: {
    audio: 'batcom-video--audio',
  },
};

export const button = {
  events: {
    button: 'Button Click',
  },
}

export const teaser = {
  events: {
    button: 'Button Click',
  },
}
